import React, { useState } from "react"
import { CSSTransition } from "react-transition-group"

import { Button } from "../buttons"
import font from "../../fonts/fonts.module.scss"
import styled from "styled-components"

import Intro from "../../components/servicos/intro"
import Sorriso from "../../components/cards/sorriso"
import SorrisoCC from "../../components/cards/sorrisoCC"

import Img from "../../images/sorrisos/sorrisos-banner.jpg"

const Sorrisos = ({ data, bypassCC }) => {
  const [CC, setCC] = useState(bypassCC ? false : true)
  const [CCFilter, setCCFilter] = useState("Implantologia")

  return (
    <StyledSorrisos>
      <Intro
        img={Img}
        positionBottom
        data={{
          title: "Sorrisos",
          text:
            "Cada caso é um caso, mas todos resultam em sorrisos felizes.\n\nContamos com uma equipa de profissionais qualificada e multidisciplinar que trata de forma personalizada cada caso clínico, com o intuito de recuperar a vontade de sorrir.\n\nApós cada tratamento, os nossos pacientes saem com confiança para viver com mais alegria e com vontade de sorrir, prontos para abraçar uma nova etapa da sua vida.",
        }}
      />
      <div role="presentation" className="hidden-anchor" id="videos">
        Videos
      </div>
      <div className="selection">
        <Button
          styleless
          className={CC ? font.rSB + " selec active" : font.rSB + " selec"}
          onClick={() => setCC(true)}
        >
          CASOS CLÍNICOS
        </Button>
        <Button
          styleless
          className={CC ? font.rSB + " selec" : font.rSB + " selec active"}
          onClick={() => setCC(false)}
        >
          TESTEMUNHOS
        </Button>
      </div>
      <div className="container">
        <CSSTransition
          in={CC === true}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            <div className="selection cc-filter">
              <Button
                styleless
                className={CCFilter==="Implantologia" ? font.rSB + " selec active" : font.rSB + " selec"}
                onClick={() => setCCFilter("Implantologia")}
              >
                IMPLANTOLOGIA
              </Button>
              <Button
                styleless
                className={CCFilter==="Ortodontia" ? font.rSB + " selec active" : font.rSB + " selec"}
                onClick={() => setCCFilter("Ortodontia")}
              >
                ORTODONTIA
              </Button>
              <Button
                styleless
                className={CCFilter==="Reabilitação Estética" ? font.rSB + " selec active" : font.rSB + " selec"}
                onClick={() => setCCFilter("Reabilitação Estética")}
              >
                REABILITAÇÃO ESTÉTICA
              </Button>
            </div>
            <div className="sorrisosCC">
              {data.casos.map((data, i) => (
                <>
                  {data.type===CCFilter &&
                    <SorrisoCC
                    data={data}
                    key={"sorrisosCaso" + i}
                    number={i}
                    />
                  }
                </>
              ))}
            </div>
          </div>
        </CSSTransition>
        <CSSTransition
          in={CC !== true}
          timeout={350}
          classNames={"switch"}
          unmountOnExit
        >
          <div className="container-content">
            {data.testemunhos.map((data, i) => (
              <Sorriso
                data={data}
                identify={"sT" + i}
                key={"sorrisosTestemunho" + i}
              />
            ))}
          </div>
        </CSSTransition>
      </div>
    </StyledSorrisos>
  )
}

export default Sorrisos

const StyledSorrisos = styled.div`
  position: relative;
  margin-bottom: 100px;
  .selection {
    display: flex;
    justify-content: center;
    margin-bottom: 3em;
    .selec {
      font-size: 16px;
      letter-spacing: 0.1em;
      position: relative;
      margin: 0 40px;
    }
    .active::after {
      content: "";
      background-color: #b7a99a;
      width: 25px;
      height: 3px;
      position: absolute;
      top: 2em;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .cc-filter{.selec{font-size: 12px;}}
  .container {
    position: relative;
  }
  .container-content {
    position: relative;
  }

  .sorrisosCC{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 50px;
    max-width: 80vw;
    grid-auto-rows: 1fr;
    margin: 0 auto;
    @media only screen and (max-width: 1100px){
      grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: 600px){
      grid-template-columns: 1fr;
    }
  }

  .switch-enter {
    position: absolute;
    top: 0 !important;
    opacity: 0 !important;
  }
  .switch-enter-active {
    position: relative;
    top: 0 !important;
    opacity: 1 !important;
    transition: all 350ms ease-out;
  }
  .switch-exit {
    position: absolute;
    top: 0 !important;
    opacity: 1 !important;
  }
  .switch-exit-active {
    position: absolute;
    top: 0 !important;
    opacity: 0 !important;
    transition: all 350ms ease-out;
  }
  .hidden-anchor {
    visibility: hidden;
    width: 0px;
    position: absolute;
    transform: translateY(-50px);
  }
`
