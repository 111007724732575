import React, { useState } from "react"
import Img from "gatsby-image"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"
import { bool } from "prop-types"
import { Button } from "../buttons"

const Sorriso = ({ data, number }) => {
  const key1 = 'open' + number
  const key2 = 'setOpen' + number

  const state = {
    [key1]: useState(null),
    [key2]: useState(null)
  }
  var elem

  const handleOpen = () => {
    state[key1][0] = true
    state[key2][0] = true
    elem = document.getElementById('modal' + number);
    elem.style.display = "table-cell";
  };

  const handleClose = () => {
    state[key1][0] = false
    state[key2][0] = false
    elem = document.getElementById('modal' + number);
    elem.style.display = "none";
  };



  return (
    <>
      <StyledSorriso>
        <div className="bg-grey">
          <div className="bg-white">
            <div className="contentTxt">
              <p className={font.rB + " title"}>
                {data.title}
              </p>
              <p className={font.rM + " text minHeight"}>{data.text}</p>
            </div>
            <Button
              styleless
              style={{ opacity: "1" }} className={font.rM + " text btn"} onClick={() => handleOpen()}>Ver mais</Button>
            <div className="contentImg" onClick={() => handleOpen()}>
              <Img
                fluid={data.img.childImageSharp.fluid}
                style={{ width: `100%`, height: "100%" }}
                imgStyle={{ objectFit: "contain" }}
                alt={data.alt && data.alt}
                className="img" 
              />
            </div>
          </div>

        </div>

        <div className="container-modal" id={"modal" + number}>
          <div className="modal">
            <Button
              styleless
              style={{ opacity: "1" }}
              onClick={() => handleClose()} className={font.rM + " text float-right"}>Fechar &times;</Button>
            <Img
              fluid={data.imgclick.childImageSharp.fluid}
              style={{ width: `100%`, height: "100%" }}
              imgStyle={{ objectFit: "contain" }}
              alt={data.alt && data.alt}
              className="img" 
            />
          </div>
        </div>
      </StyledSorriso>
    </>
  )
}

export default Sorriso


const StyledSorriso = styled.div`
  width: 100%;
  padding-bottom: 10vh;
  .minHeight{
    min-height: 15vh;
    @media (min-width: 2200px){
      min-height: 10vh;
    }
  }
  .btn {
    width: fit-content;
    padding: 0.4em 0.5em;
    margin-left: 5vh;
    border: 1px solid;
    text-transform: uppercase;
    background-color: #ffffff;
    color: #b7a99a;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    user-select: none;
    cursor: pointer;
    transition: all 0.2s;
    font-size: 13px;
    margin-top: -7vh;
    @media (min-width: 2000px){
      margin-left: 4vh;
    }
    @media (max-width: 1750px){
      margin-left: 3.5vh;
      font-size: 12px !important;
      padding: 0.6em 0.5em;
    }
    @media (max-width: 990px){
      font-size: 10px !important;
      margin-top: 0.2vh;
    }
  }
  .float-right{
    position: relative;
    top: 12vh;
    float: right;
    z-index: 999;
    @media (max-width: 990px){
      z-index: 1000;
      top: 16vh;
    }
  }
  .hide{
    display: none;
  }
  .show{
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .modal{
    width: 70vh;
    max-height: 100vh;
    height: 100vh;
    top: 15vh;
    left: auto;
    right: auto;
    text-align: center;
    margin: auto;
    @media (max-width: 990px){
      width: 100%;
      padding: 3vh; 
    }
  }
  .container-modal{
    display: none;
    position: absolute;
    top: 0vh;
    right: 0vh;
    padding-left: 0vh;
    height: 100vh;
    width: 100%;
    min-width: 100%;
    object-fit: cover;
    object-position: center;
    z-index: 999;
    width: 100%;
    max-height: 100vh;
    height: 100vh;
    background-color: rgb(239, 234, 230, 0.9);
    top: 0;
    position: fixed;
    .img{
      position: relative;
      overflow: hidden;
      object-fit: cover;
      object-position: center;
      height: auto;
      width: 100%;
      padding-bottom: 6vh;
      max-height: 100%;
    }
  }
  .bg-grey{
    background-color: #EFEAE6;
    height: auto;
    min-height: 100%;
    padding-top: 3vh;
    padding-left: 3vh;
    padding-right: 3vh;
    position: relative;
    width: 100%;
    display: table;
  }
  .bg-white{
    background-color: #ffffff;
    height: 50vh;
    min-height: 43vh;
    display: table-cell;
    padding-bottom: 11vh;
    @media (min-width: 2400px){
      height: 38vh;
      min-height: 35vh;
    }
    @media (min-width: 990px) and (max-width: 1600px){
      height: 60vh !important;
      min-height: 60vh !important;
    }
    @media (max-width: 989px){
      height: 55vh;
      min-height: 55vh;
    }
  }
  .contentImg {
    width: 36vh;
    float: right;
    padding-top: 4vh;
    position: absolute;
    right: 0px;
    margin-right: 7vh;
    cursor: pointer;
    @media (min-width: 2200px){
      height: auto;
      width: 32vh;
      margin-right: 7.56vh;
    }
    @media (max-width: 1750px){
      height: 18vh;
      padding-top: 0vh;
      width: 29vh;
      margin-top: 2vh;
    }
    @media (max-width: 990px){
      width: 23vh;
      height: auto;
      padding-top: 3vh;
    }
    .img{
      position: relative;
      width: 100%;
      float: right;
    }
    
  }
  .contentTxt {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 250ms ease-out;
    padding: 10%;
    @media (min-width: 990px) and (max-width: 1500px){
      height: auto;
      min-height: 50vh;
    }
  }
  }
  .title {
    color: #000;
    font-size: 19px;
    line-height: 1.2em;
    letter-spacing: 0.15em;
    margin-bottom: 2em;
  }
  .text {
    font-size: 15px;
    line-height: 1.5em;
    letter-spacing: 0.1em;
    
  }
  
  @media only screen and (max-width: 1300px){
    .title{
      font-size: 16px;
    }
    .text{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 1100px){
    .title{
      font-size: 20px;
    }
    .text{
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 950px){
    .title{
      font-size: 18px;
    }
    .text{
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 800px){
    .title{
      font-size: 16px;
    }
    .text{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 680px){
    .title{
      font-size: 14px;
    }
    .text{
      font-size: 10px;
    }
  }
  @media only screen and (max-width: 600px){
    .title{
      font-size: 20px;
    }
    .text{
      font-size: 14px;
    }
  }
  @media only screen and (max-width: 450px){
    .title{
      font-size: 19px;
    }
    .text{
      font-size: 13px;
    }
  }
`
