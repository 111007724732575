import React from "react"
import { graphql } from "gatsby"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Navbar from "../components/navbar/navbar"
import NavbarMobile from "../components/navbar/navbarMobile"
import Sorrisos from "../components/doublepage/sorrisos"
import SorrisosMobile from "../components/doublepage/sorrisosMobile"
import Footer from "../components/footer/footer"
import FooterMobile from "../components/footer/footerMobile"

const SorrisosPage = ({ data, location }) => (
  <Layout>
    <SEO title="Sorrisos | Carreira Dental Clinic" />
    {useBreakpoint().mobile ? (
      <>
        <NavbarMobile />
        <SorrisosMobile
          data={data.sorrisosJson.sorrisos}
          bypassCC={location?.state?.bypassCC}
        />
        <FooterMobile data={data.globalJson.footer} />
      </>
    ) : (
      <>
        <Navbar />
        <Sorrisos
          data={data.sorrisosJson.sorrisos}
          bypassCC={location?.state?.bypassCC}
        />
        <Footer data={data.globalJson.footer} />
      </>
    )}
  </Layout>
)

export default SorrisosPage

export const Json = graphql`
  query sorrisos {
    sorrisosJson {
      sorrisos {
        casos {
          title
          type
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          imgclick {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
          text
        }
        testemunhos {
          title
          img {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid
              }
            }
            publicURL
          }
          video {
            publicURL
          }
          text
        }
      }
    }
    globalJson {
      footer {
        newsletter {
          subscreva
          btnTxt
        }
        aveiro {
          title
          morada
          contacto
        }
        agueda {
          title
          morada
          contacto
        }
        porto {
          title
          morada
          contacto
        }
        viseu {
          title
          morada
          contacto
        }
        oliveira {
          title
          morada
          contacto
        }
        email
        schedule
        bg {
          publicURL
        }
      }
    }
  }
`
