import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Sorriso = ({ data, identify }) => {
  function playPause() {
    var video = document.querySelector(".aaa" + identify)
    if (video.paused) {
      video.play()
    } else {
      video.pause()
    }
  }

  return (
    <StyledSorriso>
      <div className="contentVideo">
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <video
          playsInline
          controls
          crossOrigin="anonymous"
          className={"aaa" + identify}
          poster={data.img.publicURL}
          onClick={playPause}
        >
          <source src={data.video.publicURL} type="video/mp4" />
        </video>
      </div>
      <div className="contentTxt">
        <p className={font.rB + " title"}>{data.title}</p>
        <p className={font.rM + " text"}>{data.text}</p>
      </div>
    </StyledSorriso>
  )
}

export default Sorriso

const StyledSorriso = styled.div`
  overflow: hidden;
  padding-top: calc(20px + 40 * (100vw - 320px) / 448);
  .contentImg {
    width: 80%;
    min-width: 240px;
    margin: auto;
    video {
      width: 100%;
      object-fit: cover;
    }
  }
  .contentVideo {
    width: 100%;
    min-width: 240px;
    margin: auto;
    video {
      width: 100%;
      object-fit: cover;
    }
  }
  .title {
    font-size: calc(20px + 12 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px) {
      font-size: 32px;
    }
    line-height: 1.6em;
    color: #b7a99a;
    text-align: center;
    margin: 20px 40px 0;
  }
  .subtitle {
    font-size: calc(18px + 8 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px) {
      font-size: 26px;
    }
    line-height: 1.1em;
    color: #231f20;
    text-align: center;
    margin: 0 40px 30px;
  }
  .text {
    font-size: calc(14px + 6 * (100vw - 320px) / 448);
    @media only screen and (min-width: 769px) {
      font-size: 20px;
    }
    line-height: 1.6em;
    letter-spacing: 0.1em;
    color: #808080;
    margin: 40px;
  }
`
