import React from "react"

import styled from "styled-components"
import font from "../../fonts/fonts.module.scss"

const Sorriso = ({ data, identify }) => {
  return (
    <StyledSorriso>
      <div className="contentImg">
        <video
          playsInline
          controls
          className={"aaa" + identify}
          poster={data.img.publicURL}
        >
          <source src={data.video.publicURL} type="video/mp4" />
        </video>
      </div>
      <div className="contentTxt">
        <p className={font.rB + " title"}>{data.title}</p>
        <p className={font.rM + " text"}>{data.text}</p>
      </div>
    </StyledSorriso>
  )
}

export default Sorriso

const StyledSorriso = styled.div`
  display: flex;
  padding-top: 6vw;
  align-items: center;
  .contentTxt {
    width: 60%;
    padding: calc(20px + 68 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      padding: 88px;
    }
    background-color: #eeeae6;
    height: fit-content;
  }
  .title {
    color: #b7a99a;
    font-size: calc(20px + 20 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 40px;
    }
    line-height: 1.1em;
    letter-spacing: 0.15em;
    margin-bottom: 10px;
  }
  .text {
    font-size: calc(14px + 8 * (100vw - 769px) / 1151);
    @media only screen and (min-width: 1920px) {
      font-size: 22px;
    }
    line-height: 1.5em;
    letter-spacing: 0.1em;
    color: #808080;
  }
  .contentImg {
    width: 40%;
    video {
      width: 100%;
      object-fit: cover;
    }
  }
`
